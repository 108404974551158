import { Button } from 'react-bootstrap';
import combineClasses from 'classnames';
import styles from './PrimaryButton.module.scss';

interface Props {
  disabled?: boolean;
  orange?: boolean;
  completed?: boolean;
  className?: string;
  small?: boolean;
  children?: any;
  [k: string]: string | unknown | undefined;
}

const PrimaryButton = (props: Props): JSX.Element => {
  const { small, disabled, orange, completed, className, ...otherProps } =
    props;

  const combinedClasses = combineClasses(
    {
      [`${styles.primaryButton}`]: true,
      [`${styles.small}`]: small,
      [`${styles.disabled}`]: disabled,
      [`${styles.orange}`]: orange,
      [`${styles.completed}`]: completed,
    },
    className
  );

  return (
    <div className={combinedClasses}>
      <Button {...otherProps}>{otherProps.children}</Button>
    </div>
  );
};

export default PrimaryButton;
