import AppStoreBadge from '../AppStoreBadge';
import badge from '../../images/google-play-badge.png';

const GooglePlayStoreBadge: React.VFC<{}> = () => {
  return (
    <AppStoreBadge
      id='playStoreBadge'
      targetUrl='https://play.google.com/store/apps/details?id=com.peekapak.mypeekaville.android'
      imageUrl={badge}
      altText='Download from Google Play Store'
    />
  );
};

export default GooglePlayStoreBadge;
