import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reformed from 'react-reformed';
import { setCookie } from './GlobalFunctions';
import { LoginState } from './UserManagement';
import InfoSegment from './InfoSegment';
import SpinnerContainer from './SpinnerContainer';
import {
  openHostedUIForGoogle,
  openHostedUIForClever,
  openHostedUIForCleverM,
  openHostedUIForClassLink,
} from './UserManagementHelpers';
import Footer from './Footer';
import LoginDisplayV2 from './LoginDisplayV2';
import myPeekavilleLogo from './images/myPeekaville-logo.png';
import styles from './SCSS/LoginBoxV2.module.scss';

interface FormValues {
  username: string;
  password: string;
}

type Props = {
  setProperty: () => any;
  onSubmit: (username: string, password: string) => any;
  error: string;
  nonCognitoError: string;
};

type MyState = {
  isLoggingIn: boolean;
  loginButtonText: string;
};

class LoginBox extends Component<Props, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoggingIn: false,
      loginButtonText: 'Login',
    };
  }

  myOnSubmit = (values: FormValues) => {
    const submitCredentials = () => {
      this.props.onSubmit(
        values.username.trim().toLowerCase(),
        values.password,
      );
    };
    this.setState(
      {
        isLoggingIn: true,
        loginButtonText: 'Logging in...',
      },
      submitCredentials,
    );
  };

  getLoginStatus = () => {
    if (this.props.loginState === LoginState.loggedIn) {
      return <SpinnerContainer isShow={true} />;
    } else {
      return <div className='LoginBox-status'>{this.props.errorMessage}</div>;
    }
  };

  getLoginButtonText = () => {
    switch (this.props.loginState) {
      case LoginState.loggedIn:
        return 'Logging In...';
      case LoginState.loggedOut:
        return 'Log In';
      default:
        return 'Log In';
    }
  };

  handleGoogleSignIn = () => {
    setCookie('peekapak.idP', 'Google');
    openHostedUIForGoogle();
  };

  handleCleverMSignIn = () => {
    setCookie('peekapak.idP', 'CleverM');
    openHostedUIForCleverM();
  };

  handleCleverSignIn = () => {
    setCookie('peekapak.idP', 'Clever');
    openHostedUIForClever();
  };

  handleClassLinkSignIn = () => {
    setCookie('peekapak.idP', 'ClassLink');
    openHostedUIForClassLink();
  };

  handleCleverSignInMultiplexer = () => {
    if (
      (window.location.hostname === 'localhost' &&
        window.location.port === '3008') ||
      window.location.hostname.includes('mypeekaville')
    ) {
      this.handleCleverMSignIn();
    } else {
      this.handleCleverSignIn();
    }
  };

  debounceHandle = null;

  debouncedRefresh = () => {
    clearTimeout(this.debounceHandle);
    this.debounceHandle = setTimeout(() => {
      this.forceUpdate();
    }, 500);
  };

  componentDidMount() {
    window.addEventListener('resize', this.debouncedRefresh);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedRefresh);
  }

  render() {
    return (
      <>
        <div className={styles.headerSegment}>
          <div className={styles.backgroundImgContainer} />
          <div className={styles.loginPanel}>
            <img
              className={styles.logo}
              src={myPeekavilleLogo}
              alt='myPeekapakville Logo'
            />
            <LoginDisplayV2
              model={this.props.model}
              loginState={this.props.loginState}
              myOnSubmit={this.myOnSubmit}
              getLoginButtonText={this.getLoginButtonText()}
              loginStatusMessage={this.getLoginStatus()}
              history={this.props.history}
              onGoogleSignIn={this.handleGoogleSignIn}
              onCleverSignIn={this.handleCleverSignInMultiplexer}
              onClassLinkSignIn={this.handleClassLinkSignIn}
            />
          </div>
        </div>
        <InfoSegment id='nextSegment' />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.user.loginState,
});

export default withRouter(connect(mapStateToProps, null)(reformed()(LoginBox)));
