import { createSlice } from '@reduxjs/toolkit';

export enum GameStates {
  unknown = 'UNKNOWN',
  playing = 'PLAYING',
  playingTryoutMode = 'PLAYING_TRYOUT',
  notPlaying = 'NOT_PLAYING',
}

export enum ActionType {
  PLAY_GAME = 'play',
  PLAY_TRYOUT = 'playTryout',
  STOP_GAME = 'stop',
}

export const initialState = {
  gameState: GameStates.unknown,
};

export const game = createSlice({
  name: 'game',
  initialState,
  reducers: {
    [ActionType.PLAY_GAME]: (state) => {
      state.gameState = GameStates.playing;
    },
    [ActionType.PLAY_TRYOUT]: (state) => {
      state.gameState = GameStates.playingTryoutMode;
    },
    [ActionType.STOP_GAME]: (state) => {
      state.gameState = GameStates.notPlaying;
    },
  },
});

export const { play, playTryout, stop } = game.actions;
export default game.reducer;
