import ConsoleInputForm from './ConsoleInputForm';
import tracker from './tracker-interface';
import dialogStyles from './SCSS/Dialog.module.scss';
import './ApplicationConsole.css';

const ApplicationConsole = () => (
  <div className='applicationConsole'>
    <div className={dialogStyles.dialog}>
      <div className={dialogStyles.dialogTitle}>API Console</div>
      <ConsoleInputForm initialModel={{ command: '' }} />
    </div>
  </div>
);

export default tracker.withPageView(ApplicationConsole);
