import { Component } from 'react';
import { parseFullName } from 'parse-full-name';
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import styles from './SCSS/ConsentCaptureForm.module.scss';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
type Props = {
  onSubmit: (arg0: {}) => any;
  savedRecord: {
    oid: string;
    signature: string;
    childsName: string;
    loginName: string;
    password: string;
  };
};
type State = {
  signature: string;
  childsName: string;
  loginName: string;
  password: string;
};
const fields = {
  signature: {
    minLength: 6,
    message: 'Enter your full name as your signature',
  },
  childsName: {
    minLength: 3,
    message: `Enter your child's name`,
  },
  password: {
    minLength: 6,
    message: 'Enter a password to secure the account',
  },
};

class ConsentCaptureForm extends Component<Props, State> {
  helpMessages: {
    signature?: string;
    childsName?: string;
    loginName?: string;
    password?: string;
  } = {};

  constructor(props: Props) {
    super(props);

    if (props.savedRecord) {
      this.state = { ...props.savedRecord };
    } else {
      this.state = {
        signature: '',
        childsName: '',
        loginName: '',
        password: '',
      };
    }
  }

  validateField = (field: string, isSubmit: boolean = false) => {
    delete this.helpMessages[`${field}`];
    const l = this.state[`${field}`].trim().length;
    const { minLength, message } = fields[`${field}`];

    if (l > 0 && l < minLength) {
      this.helpMessages[
        `${field}`
      ] = `${field} must be at least ${minLength} characters long`;
      return 'error';
    }

    if (l > 0 && field === 'childsName') {
      const parsedName = parseFullName(this.state.childsName);
      const { first, last } = parsedName;

      if (!first || !last) {
        this.helpMessages[`${field}`] = `Please provide your child's full name`;
        return 'error';
      }
    }

    if (l === 0 && isSubmit) {
      this.helpMessages[`${field}`] = message;
      return 'error';
    }

    if (l >= minLength) {
      return 'success';
    }

    return null;
  };
  validateForm = (isSubmit: boolean = false) => {
    const keys = Object.keys(fields);
    let i = 0;

    while (i < keys.length) {
      const value = fields[`${keys[i]}`];

      if (this.validateField(keys[i], isSubmit) === 'error') {
        return 'error';
      } else if (this.state[`${keys[i]}`].length === 0) {
        this.helpMessages[`${keys[i]}`] = `${value.message}`;
        return 'error';
      }

      i += 1;
    }

    return 'success';
  };
  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newState = {};
    newState[`${event.currentTarget.name}`] = event.currentTarget.value;
    this.setState(newState);
  };
  myOnSubmit = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateForm(true) === 'success') {
      this.props.onSubmit(this.state);
    }
  };

  render() {
    const result = this.validateForm();
    return (
      <div>
        <form
          className={styles.form}
          onSubmit={this.myOnSubmit}
          autoComplete='off'
        >
          <p>
            Type in your full name below to serve as your signature indicating
            your consent for us to collect data:
          </p>
          <FormGroup
            controlId='signature'
            validationState={this.validateField('signature')}
          >
            <FormControl
              type='text'
              name='signature'
              value={this.state.signature}
              placeholder={`Signature enter full name`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.signature && (
              <HelpBlock>{this.helpMessages.signature}</HelpBlock>
            )}
          </FormGroup>
          <FormGroup
            controlId='childsName'
            validationState={this.validateField('childsName')}
          >
            <FormControl
              type='text'
              name='childsName'
              value={this.state.childsName}
              placeholder={`Child's full name`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.childsName && (
              <HelpBlock>{this.helpMessages.childsName}</HelpBlock>
            )}
          </FormGroup>
          <FormGroup
            controlId='password'
            validationState={this.validateField('password')}
          >
            <FormControl
              type='text'
              name='password'
              value={this.state.password}
              placeholder={`Password for child`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.password && (
              <HelpBlock>{this.helpMessages.password}</HelpBlock>
            )}
          </FormGroup>
          <div className={styles.formButton}>
            <PrimaryButton
              orange
              small
              type='submit'
              onClick={this.myOnSubmit}
              disabled={result !== 'success'}
            >
              Create Account
            </PrimaryButton>
          </div>
        </form>
      </div>
    );
  }
}

export default ConsentCaptureForm;
