import PropTypes from 'prop-types';

import './NormalLink.css';

const NormalLink = (props) => (
  <span className='normalLink'>
    <a href={props.url}>{props.children}</a>
  </span>
);

NormalLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NormalLink.defaultProps = {
  children: null,
};

export default NormalLink;
