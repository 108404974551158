import { Component } from 'react';
import PropTypes from 'prop-types';
import MixpanelJS from 'mixpanel-browser';
import { getConfiguration } from './GlobalFunctions';
import { getStore } from './ApplicationState';

const mixpanelConfiguration = getConfiguration('mixpanel');

let isInteractionTimerSet = false;

const tracker = {
  setupTrackers: () => {
    MixpanelJS.init(mixpanelConfiguration.apiKey);
  },

  reset: () => {
    MixpanelJS.reset();
  },

  sendLogin: (user) => {
    MixpanelJS.identify(user.id);
    MixpanelJS.register({
      type: user.type,
      classroomId: user.classroomId,
      district: user.district || '',
      schoolId: user.schoolId || '',
      seller: user.seller || '',
      teachersId: user.type === 'Student' ? user.teachersUserId || 'NA' : 'NA',
    });
    MixpanelJS.track('user login');
  },

  sendClassCodeSignup: (classCode) => {
    const userData = getUserData();
    MixpanelJS.track('classcode signup', { classCode, ...userData });
  },

  sendPageView: (location) => {
    const userData = getUserData();
    const url = `${location.pathname}${location.search}${location.hash}`;
    MixpanelJS.track('page view', { url, ...userData });
  },

  sendTryoutVisit: (district, accountName) => {
    const userData = getUserData();
    MixpanelJS.track('tryout visit', { district, accountName, ...userData });
  },

  sendTryoutStart: (district, accountName) => {
    const userData = getUserData();
    MixpanelJS.track('tryout start', { district, accountName, ...userData });
  },

  startInteraction: () => {
    const userData = getUserData();
    if (!isInteractionTimerSet) {
      MixpanelJS.time_event('interaction end', { ...userData });
      isInteractionTimerSet = true;
    }
  },

  sendInteractionEvent: (eventType, eventName) => {
    const userData = getUserData();
    MixpanelJS.track(eventType, { eventName, ...userData });
  },

  endInteraction: function endInteraction(triggerEvent) {
    const userData = getUserData();
    if (isInteractionTimerSet) {
      MixpanelJS.track('interaction end', { triggerEvent, ...userData });
      isInteractionTimerSet = false;
    }
  },

  endInteractionOnIdle: () => {
    const userData = getUserData();
    this.endInteraction('idle', { ...userData });
  },

  endInteractionOnLeavePage: () => {
    const userData = getUserData();
    if (isInteractionTimerSet) {
      MixpanelJS.track('interaction end', {
        triggerEvent: 'leave page',
        ...userData,
      });
      isInteractionTimerSet = false;
    }

    // TODO: there is a strange problem where
    // upon hitting this code, React thinks that
    // endInteraction is not a function. If we debug
    // we can see that endInteraction is a function
    // just prior to stepping in to the function.
    // This may have to do with the component
    // getting unmounted, so we use the repeated
    // code above instead
    // this.endInteraction( 'leave page' );
  },

  withPageView: (BaseComponent) => {
    class WithPageView extends Component {
      componentWillMount() {
        // tracker.sendPageView(this.props.location);
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    WithPageView.propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        hash: PropTypes.string,
        search: PropTypes.string,
      }).isRequired,
    };

    return WithPageView;
  },
};

function getUserData() {
  const { user } = getStore().getState();

  if (!user?.userProfile) {
    return {};
  }

  const userProfile = user.userProfile;

  return {
    userId: userProfile.userId,
    type: userProfile.type,
    classroomId: userProfile.classroomId,
    district: userProfile.district ?? '',
    schoolId: userProfile.schoolId ?? '',
    seller: userProfile.seller ?? '',
  };
}

export default tracker;
