import NormalLink from './NormalLink';
import PeekapakButton from './PeekapakButton';
import tracker from './tracker-interface';
import artwork from './images/disappointed-zoey.png';
import styles from './SCSS/NoParentalConsent.module.scss';
import dialogStyles from './SCSS/Dialog.module.scss';
type Props = {
  location: {
    search: string;
    state: {
      isValid: boolean;
      isExpired: boolean;
    };
  };
  history: {
    push: (arg0: string) => void;
  };
  isLicenseValid: boolean;
  isLicenseExpired: boolean;
};
let history;

const onButtonClick = () => history.push('/');

const Layout = (props: Props) => {
  /*
  const query = new URLSearchParams( props.location.search );
  const valid = query.get( 'valid' ) === 'true';
  const expired = query.get( 'expired' ) === 'true';
  */
  history = props.history;
  const { isLicenseValid, isLicenseExpired } = props;

  const invalidLicenseMessage = () => (
    <>
      <p>
        Unfortunately your account doesn't have a license to play in
        myPeekaville. If this is wrong or you have any questions, feel free to
        contact us at{' '}
        <NormalLink url='mailto:hello@peekapak.com'>
          hello@peekapak.com
        </NormalLink>
      </p>
      <p className={styles.button}>
        <PeekapakButton onClick={onButtonClick} bsSize='small'>
          Return to login page
        </PeekapakButton>
      </p>
    </>
  );

  const expiredLicenseMessage = () => (
    <>
      <p>
        Your license to play in myPeekaville has expired! If you have any
        questions, feel free to contact us at{' '}
        <NormalLink url='mailto:hello@peekapak.com'>
          hello@peekapak.com
        </NormalLink>
      </p>
      <p className={styles.button}>
        <PeekapakButton onClick={onButtonClick} bsSize='small'>
          Return to login page
        </PeekapakButton>
      </p>
    </>
  );

  let printProblemMessage;

  if (isLicenseValid && isLicenseExpired) {
    printProblemMessage = expiredLicenseMessage;
  } else {
    printProblemMessage = invalidLicenseMessage;
  }

  return (
    <div className={styles.noParentalConsent}>
      <div className={dialogStyles.dialog}>
        <div className={styles.licenseProblemsContentLayout}>
          <div className={styles.dialogTitle}>Sorry!</div>
          <div className={styles.dialogText}>{printProblemMessage()}</div>
        </div>
        <img
          className={styles.illustration}
          src={artwork}
          alt='Zoey is disappointed'
        />
      </div>
    </div>
  );
};

export default tracker.withPageView(Layout);
