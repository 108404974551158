const Documents = {
  blogNewFeatures2021: 'http://blog.peekapak.com/new-features-for-2020-2021/',
  buyersGuide:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/marketing/PeekapakBuyersGuide.pdf',
  canadianStandards:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak+Correlations+To+Canadian+Education+Expectations.pdf',
  caseStudy:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Case_Study.pdf',
  casel:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/marketing/Peekapak-CASEL+Alignment+-+2019+.pdf',
  characterCards:
    'https://s3.amazonaws.com/peekaplatform/media/resources/peekapakPalsCharacterCards.pdf',
  connectingToParents:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Step-by-step+guide-Connecting+to+parents.pdf',
  consentForm:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/help/Joining+myPeekaville+consent+form.pdf',
  consentFormSpanish:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/help/Joining+myPeekaville+consent+form+-+Spanish.pdf',
  federalFunding:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/UpdatedReview_FederalFundingSheet.pdf',
  guidedReadingLevels:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Peekapak_Guided_Reading_Levels_03-05-19.pdf',
  howToUseUnit:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Step-by-step+guide-How+to+use+a+Peekapak+unit.pdf',
  introLetter:
    'https://s3.amazonaws.com/peekaplatform/media/resources/introLetter.pdf',
  meetThePeekapakPals:
    'https://s3.amazonaws.com/peekaplatform/media/resources/meetThePeekapakPals.pdf',
  parentsEmailRequestForm:
    'https://s3.amazonaws.com/peekaplatform/media/resources/parentsEmailRequestForm.pdf',
  posterCourage:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Courage-pk-UnitResources-Courage%20Poster.pdf',
  posterEmpathy:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Empathy-pk-UnitResources-Empathy%20Poster.pdf',
  posterGratitude:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Gratitude-pk-UnitResources-Gratitude%20Poster.pdf',
  posterHonesty:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Honesty-pk-UnitResources-Honesty%20Poster.pdf',
  posterKindness:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Kindness-pk-UnitResources-Kindness%20Poster.pdf',
  posterOptimism:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Optimism-pk-UnitResources-Optimism%20Poster.pdf',
  posterPerseverance:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Perseverance-pk-UnitResources-Perseverance%20Poster.pdf',
  posterRespect:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Respect-pk-UnitResources-Respect%20Poster.pdf',
  posterSelfRegulation:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Self-Regulation-pk-UnitResources-Self-Regulation%20Poster.pdf',
  posterTeamwork:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Teamwork-pk-UnitResources-Teamwork%20Poster.pdf',
  pressKit:
    'https://drive.google.com/drive/u/0/folders/1byysypNq2xv6oxDP2iTCHmCnOI4bwbrC',
  remoteLearningForFamilies:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/Remote+Learning+for+Families.pdf',
  research:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/PEEKAPAK_-_Research_%26_Design_Document_-_2019.pdf',
  settingUpYourClass:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/Step-by-step+guide-Setting+up+your+class.pdf',
  twoYearCurriculumPlanner:
    'https://s3.amazonaws.com/peekaplatform/media30/resources/2+Year+Curriculum+Planner+2018-2019.pdf',
  unitCheatSheet:
    'https://peekaplatform.s3.amazonaws.com/media30/resources/help/myPeekaville+Units+Cheat+Sheet.pdf',
  requestInfoAndQuote: 'https://peekapak.com/requestInfo',
  blogCoronaVirusLessons: 'http://blog.peekapak.com/coronavirus-lesson-plans/',
  blogGetStartedPeekapak:
    'http://blog.peekapak.com/getting-started-with-peekapak/',
  videoHowToUsePeekapak:
    'https://www.youtube.com/watch?v=gZMjCbXMJH8&feature=emb_logo',
  calendarAmiDemo: 'https://calendly.com/amishah/30min',
  blogSite: 'http://blog.peekapak.com',
  careersSite: 'https://peekapak.com/careers',
  shopSite: 'https://shop.peekapak.com',
  bookDemo: 'https://calendly.com/deannekt/30min?hide_event_type_details=1',
  minuteIntro: `https://m.youtube.com/watch?v=IP8gwmNaIQE`,
  middleSchoolResearch: `https://peekaplatform.s3.amazonaws.com/media30/resources/Peekapak's+Research-Informed+M.S.+Curriculum.pdf`,
  freeRemoteResources: `https://blog.peekapak.com/free-access-to-peekapak-resources-for-canadian-families-2022/`,
};

export default Documents;
