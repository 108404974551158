import Auth from '@aws-amplify/auth';
import { getStore } from './ApplicationState';
import {
  CognitoUserProfileType,
  LoginState,
  expireSession,
  login,
  loginPartial,
  logout,
  reactivateSession,
  setUserProfile,
  startReactivation,
  updateField,
  UserType,
  LicenseLevel,
} from './UserManagement';
import {
  getCleverOwnProfile,
  getCleverToken as gct,
  getUserProfileForMigration,
  resendNewPasswordConfirmation as rnpc,
} from './BackendInterface';
import {
  expireSessionAndRedirect,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from './aaa';
import {
  deleteCookie,
  getCleverProfile,
  getConfiguration,
  getCookie,
  isTokenExpired,
  noWhiteSpace,
  setCookie,
} from './GlobalFunctions';
import { setUser, unsetUser } from './error-tracker';
declare let gapi: Record<string, any>;
export type Provider = 'Google' | 'Clever' | 'CleverM' | 'ClassLink';
export type ConfirmationLinkState = 'EXPIRED' | 'OK' | 'STALE';

interface CleverProfile {
  data: {
    authorized_by: string;
    district: string;
    id: string;
    type: string;
  };
}

function throwErrorIfUserIsElementaryStudent(profile: UserProfileType) {
  if (isElementaryStudent(profile)) {
    throw new Error('Elementary student users not allowed on this site');
  }
}

export function isElementaryStudent(profile: UserProfileType) {
  const { grade, type } = profile;
  const isStudent = type === 'Student';
  return isStudent && grade < 6;
}

export function getCleverToken(
  authorizationCode: string,
  redirectUri: string
): Promise<GetCleverTokenResponse> {
  return new Promise((resolve, reject) => {
    gct(
      authorizationCode,
      redirectUri,
      (data) => {
        return resolve(data);
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
export function openHostedUIForGoogle() {
  openHostedUI('Google');
}
export function openHostedUIForClassLink() {
  openHostedUI('ClassLink');
}
export function openHostedUIForClever() {
  openHostedUI('Clever');
}

export function openHostedUIForCleverM() {
  openHostedUI('CleverM');
}

export function openHostedUI(provider: Provider) {
  Auth.federatedSignIn({
    provider,
  });
}

export function resendConfirmationCode(username: string) {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username)
      .then((data) => {
        return resolve(data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

export function checkConfirmationCode(username: string, code: string) {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, code)
      .then((data) => {
        return resolve(data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
export async function reactivateUserSession() {
  try {
    await reactivateUserSessionIfExists();
  } catch (error) {
    if (!isInnocuousError(error)) {
      throw error;
    }
  }
}

/** obtain the user's Cognito User Profile with a fresh session token
 */
export async function getRefreshedCognitoUser(): Promise<CognitoUserProfileType> {
  const session = await Auth.currentSession();

  // console.debug(
  //   `%cReactivating -  got current session`,
  //   'background: black; color: yellow'
  // );

  const cognitoUser = await Auth.currentAuthenticatedUser();

  cognitoUser.refreshSession(session.getRefreshToken(), () => {
    // console.debug(
    //   '%cRefreshed auth session',
    //   'background: black; color: yellow'
    // );
  });

  const expiryFromUser = cognitoUser.getSignInUserSession().getIdToken()
    .payload.exp;
  const expiryFromSession = session.getIdToken().payload.exp;

  // console.debug(
  //   `%cReactivating - got cognito user, user session expires ${getReadableUnixTimeAnsi(
  //     expiryFromUser * 1000
  //   )} / ${expiryFromUser}, from session = ${expiryFromSession}`,
  //   'background: black; color: yellow'
  // );

  return cognitoUser;
}

/* eslint-disable complexity */
export async function reactivateUserSessionIfExists(): Promise<void> {
  const currentLoggedInState = getStore().getState().user.loginState;

  if (currentLoggedInState === LoginState.storybookAccess) {
    // console.debug(`%cParent storybook access detected`);
    return;
  }

  if (currentLoggedInState === LoginState.loggedIn) {
    const expiry =
      getStore().getState().user.cognitoProfile.signInUserSession.idToken
        .payload.exp;

    if (!isTokenExpired(expiry)) {
      return;
    }

    // console.debug(
    //   `%cToken is expired ...`,
    //   'background: blue; color: yellow',
    //   `time to expiry = `,
    //   Math.floor(Date.now() / 1000) - expiry
    // );
  }

  // console.debug(
  //   `%cReactivating - no longer logged in or too close to token expiry -> `,
  //   'background: black; color: yellow',
  //   currentLoggedInState
  // );

  if (
    currentLoggedInState === LoginState.reactivating ||
    currentLoggedInState === LoginState.authenticated
  ) {
    // console.debug(
    //   `%cReactivating - login state is reactivating, subscribing`,
    //   'background: black; color: yellow'
    // );
    return new Promise((resolve) => {
      const unsubscribe = getStore().subscribe(() => {
        const observedState = getStore().getState().user.loginState;

        if (observedState !== currentLoggedInState) {
          unsubscribe();
          return resolve();
        }
      });
    });
  }

  getStore().dispatch(startReactivation());

  // console.debug(
  //   `%cReactivating has started`,
  //   'background: black; color: yellow'
  // );

  try {
    const cognitoUser = await getRefreshedCognitoUser();

    if (nativeAccountAlreadyExists(cognitoUser)) {
      throw new Error(`User Pool account already exists`);
    }

    getStore().dispatch(reactivateSession(cognitoUser));

    let peekapakUserProfile;
    try {
      peekapakUserProfile = await getUserProfileFromDB();
    } catch (getUserProfileError) {
      getStore().dispatch(loginPartial());
      throw getUserProfileError;
    }

    // console.debug(
    //   `%cReactivating - got user profile ${peekapakUserProfile.userId}`,
    //   'background: black; color: yellow'
    // );
    getStore().dispatch(setUserProfile(peekapakUserProfile));
    setUser(peekapakUserProfile.userId);

    const identities = cognitoUser.signInUserSession.idToken.payload.identities;

    if (identities) {
      // console.debug(
      //   `%cReactivating - figuring out external identities`,
      //   'background: black; color: yellow'
      // );

      const googleIdentity = identities.filter((identity) => {
        return identity.providerName === 'Google';
      });

      if (googleIdentity.length > 0) {
        const googleUserId = googleIdentity[0].userId;
        // console.debug( `User has Google identity: ${ googleUserId }` );
        await initializeGoogleApiClients(googleUserId);
        getStore().dispatch(
          updateField({
            field: 'googleAccountUserId',
            value: googleUserId,
          })
        );
      }

      const cleverIdentity = identities.filter((identity) => {
        // will match Clever and CleverM
        return identity.providerName.includes('Clever');
      });

      if (cleverIdentity.length > 0) {
        const cleverAccessToken =
          cognitoUser?.attributes?.['custom:pmAccessToken'];

        if (cleverAccessToken) {
          const cleverAccountUserId =
            cognitoUser?.attributes?.['custom:pmUserId'];

          if (!cleverAccountUserId)
            throw new Error(
              `Expected to find Clever User ID in account but did not`
            );

          try {
            const cleverProfile = (await getCleverProfile(
              cleverAccountUserId,
              cleverAccessToken
            )) as Record<string, unknown>;

            updateCleverCredentials(
              { data: { id: cleverAccountUserId } },
              cleverAccessToken,
              { data: cleverProfile }
            );
          } catch {
            // could not get clever profile so probably the access token has
            // expired
          }
        }
      }
    }

    // console.debug(
    //   `%cReactivating - figuring out Clever access`,
    //   'background: black; color: yellow'
    // );
    console.info(
      `User ${
        peekapakUserProfile?.userId || peekapakUserProfile?.email
      } session reactivated`
    );
  } catch (err) {
    if (!(err instanceof Error) && typeof err !== 'string') {
      throw err;
    }

    const errorMessage = (err as Error)?.message || err;

    switch (errorMessage) {
      case 'No current user':
        getStore().dispatch(logout());
        break;

      case 'Refresh Token has expired':
        expireSessionAndRedirect(); // will dispatch expire session

        break;

      case 'Network error':
        console.warn(noWhiteSpace`Network error prevented reactivate user: ${errorMessage}, \
            possibly logged out. Current loginState === ${
              getStore().getState().user.loginState
            }`);
        break;

      case 'New Library user account created':
        console.debug(errorMessage);
        setCookie('peekapak.reloginIdp', 'true', 1);
        Auth.signOut();

        break;

      default:
        console.warn(noWhiteSpace`Cannot reactivate user: ${errorMessage}, \
            possibly logged out. Current loginState === ${
              getStore().getState().user.loginState
            }`);
        break;
    }

    throw err;
  }

  function nativeAccountAlreadyExists(data) {
    const { payload } = data.signInUserSession.idToken;
    return payload.isUserPoolAccountAlreadyExists;
  }
}
export function getUserProfileFromDB(): Promise<UserProfileType> {
  return new Promise((resolve, reject) => {
    getUserProfileForMigration(
      (response) => {
        return resolve(response);
      },
      (error) => {
        return reject(new Error(error.message));
      }
    );
  });
}
export function resendNewPasswordConfirmation(username: string): Promise<void> {
  return new Promise((resolve, reject) => {
    rnpc(
      username,
      (_) => {
        return resolve();
      },
      (error) => {
        return reject(error);
      }
    );
  });
}
export function forceChangePassword(
  username: string,
  temporaryPassword: string,
  newPassword: string
): Promise<{
  email: string;
}> {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, temporaryPassword)
      .then((user) => {
        if (
          !user.challengeName ||
          user.challengeName !== 'NEW_PASSWORD_REQUIRED'
        ) {
          return reject(
            new Error(
              `Did not receive expected new password required challenge`
            )
          );
        }

        return Auth.completeNewPassword(
          user,
          newPassword,
          user.challengeParam.requiredAttributes
        );
      })
      .then((user) => {
        getStore().dispatch({
          type: 'LOGIN',
          payload: user,
        });
        return getUserProfileFromDB();
      })
      .then((userProfile) => {
        getStore().dispatch({
          type: 'SET_USER_PROFILE',
          payload: userProfile,
        });
        console.info(
          `Force change password succeeded for ${userProfile.email}`
        );
        setUser(userProfile.userId);
        return resolve(userProfile);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
export function getNewPasswordConfirmationLinkState(
  username: string,
  temporaryPassword: string
): Promise<ConfirmationLinkState> {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, temporaryPassword)
      .then((user) => {
        if (
          !user.challengeName ||
          user.challengeName !== 'NEW_PASSWORD_REQUIRED'
        ) {
          return reject(
            new Error(
              `Did not receive expected new password required challenge`
            )
          );
        }

        return resolve('OK');
      })
      .catch((error) => {
        if (error.message.includes('Incorrect username or password')) {
          return resolve('STALE');
        } else if (
          error.message.includes(
            'User account has expired, it must be reset by an administrator.'
          )
        ) {
          return resolve('EXPIRED');
        } else if (error.message.includes('Temporary password has expired')) {
          return resolve('EXPIRED');
        }

        return reject(error);
      });
  });
}
export async function signInUser(
  email: string,
  password: string
): Promise<UserProfileType> {
  const account = await Auth.signIn(email, password);
  getStore().dispatch(login(account));
  const profile = await getUserProfileFromDB();

  console.info(`${profile.email} sign in successful`);
  getStore().dispatch(setUserProfile(profile));
  setUser(profile.userId);
  return profile;
}

export async function userChangePassword(
  oldPassword: string,
  newPassword: string
) {
  const currentUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(currentUser, oldPassword, newPassword);
  return true;
}
export async function initiateForgotPasswordFlow(username: string) {
  const data = await Auth.forgotPassword(username);
  return data;
}
export async function forgotPasswordSubmit(
  username: string,
  verificationCode: string,
  newPassword: string
) {
  const data = await Auth.forgotPasswordSubmit(
    username,
    verificationCode,
    newPassword
  );
  return data;
}
export async function signOutUser() {
  try {
    const loggedInUser = getStore().getState().user;
    const currentCognitoUser = await Auth.currentAuthenticatedUser();

    if (loggedInUser && loggedInUser.cognitoProfile) {
      console.info(`Logging out ${loggedInUser.cognitoProfile.username}...`);

      signOutFromLocalState();
      signOutOfCognito();
      return;
    } else if (currentCognitoUser) {
      signOutOfCognito();
      return;
    }
  } catch (error) {
    if (!isInnocuousError(error as Error)) {
      throw error;
    }

    return;
  }

  function signOutFromLocalState() {
    getStore().dispatch(logout());
    unsetUser();
  }

  function signOutOfCognito(): Promise<void> {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(() => {
          return resolve();
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }
}
export function isUserParentAccessStorybook(): boolean {
  return getStore().getState().user.loginState === LoginState.storybookAccess;
}
export function isUserDistrictAdministrator(): boolean {
  const userProfile = getStore().getState().user?.userProfile;
  if (!userProfile) return false;

  return (
    userProfile.userType === UserType.districtAdmin ||
    userProfile.licenseLevel === LicenseLevel.districtAdministrator
  );
}

export function isUserLoggedIn(): boolean {
  return getStore().getState().user.loginState === LoginState.loggedIn;
}
export function isUserLoggedInStateUnknown(): boolean {
  const currentState = getStore().getState().user.loginState;
  return currentState === LoginState.unknown;
}
export function isUserLoggedInStateInTransition(): boolean {
  const currentState = getStore().getState().user.loginState;
  return (
    currentState === LoginState.unknown ||
    currentState === LoginState.reactivating ||
    currentState === LoginState.authenticated
  );
}
export function isTokenUnavailable(): boolean {
  const currentState = getStore().getState().user.loginState;
  // console.debug(`isTokenUnavailable currentState = `, currentState);
  return (
    currentState === LoginState.unknown ||
    currentState === LoginState.reactivating
  );
}
export function waitUntilLoginState(
  timeout = 10000,
  isReadyFunction: (arg0: string) => boolean
): Promise<void> {
  return new Promise((resolve, reject) => {
    const intervalLength = 500;
    let intervalCount = 0;
    const intervalId = setInterval(checkState, intervalLength);

    function checkState() {
      const currentState = getStore().getState().user.loginState;
      // console.debug(
      //   `%ccurrentState `,
      //   'background: blue; color: yellow',
      //   currentState
      // );

      if (isReadyFunction.call(null, currentState)) {
        clearInterval(intervalId);
        return resolve();
      }

      intervalCount += 1;

      if (intervalLength * intervalCount > timeout) {
        clearInterval(intervalId);
        return reject(Error(`waitUntilUserLoginState Timeout expired`));
      }
    }
  });
}
export async function waitUntilTokenIsAvailable(
  timeout = 10000
): Promise<void> {
  return await waitUntilLoginState(timeout, (currentState) => {
    return (
      currentState !== LoginState.unknown &&
      currentState !== LoginState.reactivating
    );
  });
}
export async function waitUntilUserLoggedInStateKnown(
  timeout = 20000
): Promise<void> {
  return await waitUntilLoginState(timeout, (currentState) => {
    return (
      currentState !== LoginState.unknown &&
      currentState !== LoginState.authenticated &&
      currentState !== LoginState.reactivating
    );
  });
}
export function getRefreshTokenExpiry() {
  const initialAuthTime = getStore()
    .getState()
    .user.cognitoProfile.getSignInUserSession()
    .getIdToken().payload.auth_time;

  // the refresh token expiry is hardcoded and set in
  // the Cognito user pool app client configuration
  return initialAuthTime + 60 * 60 * 24;
}

export function isUserSessionExpired(): boolean {
  if (isUserLoggedIn()) {
    const refreshTokenExpiry = getRefreshTokenExpiry() + 60 * 60 * 24;
    const expired = isTokenExpired(getRefreshTokenExpiry());
    if (expired) {
      getStore().dispatch(expireSession());
      return true;
    }

    return false;
  }

  console.warn(
    `User is not logged in but session expiry is being checked. This could be a logical error.`
  );
  return false;
}

export function initializeGoogleApiClients(googleUserId = ''): Promise<void> {
  return new Promise((resolve) => {
    if (gapi && gapi.auth2 && gapi.client.classroom) {
      resolve();
    }

    const auth2Params = {
      client_id: getConfiguration('google').clientId,
      immediate: true,
      login_hint: '',
    };
    const driveClientParams = {
      discoveryDocs: getConfiguration('google').discoveryDocs,
      immediate: true,
      login_hint: '',
    };

    if (googleUserId) {
      auth2Params.login_hint = googleUserId;
      driveClientParams.login_hint = googleUserId;
    } else {
      delete auth2Params.login_hint;
      delete driveClientParams.login_hint;
    }

    gapi.load('client:auth2', () => {
      // console.debug( 'GAPI: client auth api loaded' );
      gapi.auth2.init(auth2Params).then(() => {
        // console.debug( 'GAPI: auth2 init done' );
        gapi.client.init(driveClientParams).then(() => {
          // console.debug( 'GAPI: classroom client init done' );
          resolve();
        });
      });
    });
  });
}
export function isGoogleUserSignedIn(): boolean {
  if (gapi && gapi.auth2) {
    return gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  return false;
}
export async function isCleverUserSignedIn(): Promise<boolean> {
  const currentLoggedInState = getStore().getState().user.loginState;

  if (currentLoggedInState === LoginState.loggedIn) {
    const userProfile = getStore().getState().user.userProfile;
    if (userProfile) {
      // NB2: The below NB may not be true.....
      // NB: we do not need to check the expiry; the Clever access token would
      // have been obtained when the user logged in using Clever, so if the
      // user's Cognito refresh token is still valid, then the Clever access
      // token will be as well, since they both expire 24 hours after grant. If,
      // on the other hand, the user logged in using Cognito and then later
      // obtained a Clever access token by clicking on the + Sync using Clever
      // button, then the Clever access token will not expire before the Cognito
      // one does, which will prompt the user to re-login
      if (
        userProfile?.cleverAccessToken &&
        userProfile.cleverAccessToken.exp > Date.now()
      ) {
        return true;
      }

      // userProfile exists but no clever access token
      const cognitoProfile = getStore().getState().user.cognitoProfile;
      if (cognitoProfile?.attributes['custom:pmAccessToken']) {
        const at = cognitoProfile.attributes['custom:pmAccessToken'];

        try {
          const cleverProfile = (await getCleverOwnProfile(
            at
          )) as CleverProfile;
          updateCleverCredentials(cleverProfile, at, cleverProfile, true);

          return true;
        } catch {
          return false;
        }
      }
    }

    return false;
  }

  return false;
}
export function getSignedInGoogleUserId(): void {
  if (gapi && gapi.auth2 && isGoogleUserSignedIn()) {
    return gapi.auth2.getAuthInstance().currentUser.get().getId();
  }

  throw new Error(`Google user not signed in`);
}
export async function promptUserGoogleSignIn(): Promise<void> {
  const { scope } = getConfiguration('google');

  if (gapi && gapi.auth2) {
    await gapi.auth2.getAuthInstance().signIn({
      prompt: 'select_account',
      scope,
    });
  } else {
    throw new Error(`Google API clients not initialized`);
  }
}
export async function signOutGoogleAccount() {
  if (gapi && gapi.auth2) {
    await gapi.auth2.getAuthInstance().signOut();
  } else {
    throw new Error(`Google API clients not initialized`);
  }
}
export function isGoogleApiInitialized() {
  const signedIn = isSignedIn();
  const classroomApiAccessible = !!gapi.client && !!gapi.client.classroom;
  return signedIn && classroomApiAccessible;

  function isSignedIn() {
    return (
      gapi &&
      gapi.auth2 &&
      gapi.auth2.getAuthInstance() &&
      gapi.auth2.getAuthInstance().isSignedIn.get()
    );
  }
}

function storeCleverCredentialsInProfile(
  cleverAccountUserId: string,
  cleverAccessToken: Record<string, unknown>,
  cleverProfile: Record<string, unknown>
) {
  getStore().dispatch(
    updateField({ field: 'cleverAccountUserId', value: cleverAccountUserId })
  );
  getStore().dispatch(
    updateField({ field: 'cleverAccessToken', value: cleverAccessToken })
  );
  getStore().dispatch(
    updateField({ field: 'cleverProfile', value: cleverProfile })
  );
}

export function updateCleverCredentials(
  cleverId: {
    data: {
      id: string;
    };
  },
  cleverAccessToken: string,
  profile: {
    data: Record<string, unknown>;
  },
  isExpiryUnknown?: boolean
) {
  const cleverAccountUserId = cleverId.data.id;
  const cleverProfile = profile.data;
  const cleverAccessTokenWrapper = {
    token: cleverAccessToken,
    // NB: if we don't know the expiry, set the expiry to 10 minutes from the
    // present moment
    expiry: isExpiryUnknown
      ? Date.now() + 600 * 1000
      : Date.now() + 24 * 3600 * 1000,
  };
  storeCleverCredentialsInProfile(
    cleverAccountUserId,
    cleverAccessTokenWrapper,
    cleverProfile
  );
}

export function isStudent(userProfile: UserProfileType | null) {
  return userProfile?.type === 'Student';
}

function isInnocuousError(error: unknown) {
  const message = (() => {
    if ((error as Error).message) return (error as Error).message;
    return error;
  })();

  switch (message) {
    case 'The user is not authenticated':
    case 'No current user':
      console.warn(`Innocuous error: ${message}`);
      return true;
    default:
      return false;
  }
}
