import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum SignUpStates {
  withoutClassCode = 'NO_CLASSCODE',
  withClassCode = 'CLASSCODE',
  pending = 'PENDING',
  success = 'SUCCESS',
  error = 'ERROR',
}

export enum ActionType {
  SUBMIT_CLASSCODE = 'submitClassCode',
  REMOVE_CLASSCODE = 'removeClassCode',
  SUBMIT = 'submit',
  FETCH_DATA = 'fetchData',
  TELL_SUCCESS = 'tellSuccess',
  TELL_ERROR = 'tellError',
}

export const initialState = {
  signUpState: SignUpStates.withClassCode,
};

export const signUp = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    [ActionType.SUBMIT_CLASSCODE]: (state) => {
      state.signUpState = SignUpStates.withClassCode;
    },
    [ActionType.REMOVE_CLASSCODE]: (state) => {
      state.signUpState = SignUpStates.withoutClassCode;
    },
    [ActionType.SUBMIT]: (state) => {
      state.signUpState = SignUpStates.pending;
    },
    [ActionType.FETCH_DATA]: (state) => {
      state.signUpState = SignUpStates.pending;
    },
    [ActionType.TELL_SUCCESS]: (state) => {
      state.signUpState = SignUpStates.success;
    },
    [ActionType.TELL_ERROR]: (state) => {
      state.signUpState = SignUpStates.error;
    },
  },
});

export const {
  submitClassCode,
  removeClassCode,
  submit,
  fetchData,
  tellSuccess,
  tellError,
} = signUp.actions;
export default signUp.reducer;
