import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Main.css';

const Main = ({ children, className }) => (
  <div className={classNames('Main', className)}>{children}</div>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Main.defaultProps = {
  className: '',
};

export default Main;
