//
// Create a redirector for pre-authorized logins from the Clever Portal
//
// It’s possible for someone who’s using the Clever Portal which hosts all their
// educational apps to try to launch Peekapak from there. The expectation is
// that if they’ve already logged in using their Clever account there, then they
// shouldn’t need to do that again.
//
// This new route/page creates an endpoint where we determine that the user is
// pre-authorized. Then we “forward” this request to the regular federated sign
// in for Clever.
//
// This results in a minimum introduction of new code.
//
// This should also work for Google, though a “portal” that behaves similarly to
// Clever doesn’t exist as far as we know.
//
import { Component } from 'react';
import {
  openHostedUIForClever,
  openHostedUIForGoogle,
  openHostedUIForCleverM,
  openHostedUIForClassLink,
} from './UserManagementHelpers';
import { setCookie } from './GlobalFunctions';
import StatusUpdate from './NewSignUpStatusUpdate';

type Props = {
  setProperty: (arg0: string, arg1: string) => void;
  loginState: string;
  location: {
    pathname: string;
    state: {
      nextPathname: string;
    };
    query: {
      code?: string;
      error_description?: string;
    };
  };
  router: {
    replace: (arg0: string) => void;
    push: (arg0: string) => void;
  };
  params: {
    providerId: string;
  };
};
type State = {
  isShowSpinner: boolean;
  isShowNonexistentAccountDialog: boolean;
};

class OauthAutoLogin extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowSpinner: true,
    };
  }

  componentDidMount = () => {
    const { providerId } = this.props.params;

    switch (providerId) {
      case 'Google':
        this.handleGoogleSignIn();
        break;

      case 'Clever':
        this.handleCleverSignIn();
        break;

      case 'ClassLink':
        this.handleClassLinkSignIn();
        break;

      case 'CleverM':
      default:
        this.handleCleverMSignIn();
        break;
    }
  };
  handleGoogleSignIn = () => {
    setCookie('peekapak.idP', 'Google');
    setCookie('peekapak.oauthSignInOrigin', 'preauth');
    openHostedUIForGoogle();
  };
  handleCleverSignIn = () => {
    setCookie('peekapak.idP', 'Clever');
    setCookie('peekapak.oauthSignInOrigin', 'preauth');
    openHostedUIForClever();
  };
  handleCleverMSignIn = () => {
    setCookie('peekapak.idP', 'CleverM');
    setCookie('peekapak.oauthSignInOrigin', 'preauth');
    openHostedUIForCleverM();
  };
  handleClassLinkSignIn = () => {
    setCookie('peekapak.idP', 'ClassLink');
    setCookie('peekapak.oauthSignInOrigin', 'preauth');
    openHostedUIForClassLink();
  };
  render() {
    return (
      <>
        {this.state.isShowSpinner && (
          <StatusUpdate
            pictureUrl='https://s3.amazonaws.com/peekaplatform/media30/images/apollo-with-whiteboard.png'
            contentTitle='Processing...'
            isShowSpinner={true}
          />
        )}
      </>
    );
  }
}

function ClassFunctionWrapper(props) {
  const params = {};
  const query = new URLSearchParams(props.location.search);
  params.providerId = query.get('providerId');
  return <OauthAutoLogin {...props} params={params} />;
}

export default ClassFunctionWrapper;
