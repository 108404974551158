import peekapakLogo from '../../images/peekapak-logo-white.svg';
import styles from './Banner.module.scss';

interface Props {
  isLoggedIn?: boolean;
}

const Banner = ({ isLoggedIn = false }: Props): JSX.Element => {
  return (
    <section className={styles.bannerContainer}>
      {isLoggedIn === false && (
        <img
          src={peekapakLogo}
          alt='peekapak logo'
          onClick={() => window.open('https://www.peekapak.com/')}
        />
      )}
    </section>
  );
};

export default Banner;
