import { Component } from 'react';
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import styles from './SCSS/ConsentCaptureForm.module.scss';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
type Props = {
  onSubmit: (arg0: { classCode: string }) => any;
  savedClassCode: string;
};
type State = {
  classCode: string;
};
const fields = {
  classCode: {
    minLength: 6,
    message: 'Enter the class code from your invitation email or letter',
  },
};

class ConsentCaptureForm extends Component<Props, State> {
  helpMessages: {
    classCode?: string;
  } = {};

  constructor(props: Props) {
    super(props);

    if (props.savedClassCode) {
      this.state = {
        classCode: props.savedClassCode,
      };
    } else {
      this.state = {
        classCode: '',
      };
    }
  }

  validateField = (field: string, isSubmit: boolean = false) => {
    delete this.helpMessages[`${field}`];
    const l = this.state[`${field}`].trim().length;
    const { minLength, message } = fields[`${field}`];

    if (l > 0 && l < minLength) {
      this.helpMessages[
        `${field}`
      ] = `${field} must be at least ${minLength} characters long`;
      return 'error';
    }

    if (l === 0 && isSubmit) {
      this.helpMessages[`${field}`] = message;
      return 'error';
    }

    if (l >= minLength) {
      return 'success';
    }

    return null;
  };
  validateForm = (isSubmit: boolean = false) => {
    const keys = Object.keys(fields);
    let i = 0;

    while (i < keys.length) {
      const value = fields[`${keys[i]}`];

      if (this.validateField(keys[i], isSubmit) === 'error') {
        return 'error';
      } else if (this.state[`${keys[i]}`].length === 0) {
        this.helpMessages[`${keys[i]}`] = `${value.message}`;
        return 'error';
      }

      i += 1;
    }

    return 'success';
  };
  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newState = {};
    newState[
      `${event.currentTarget.name}`
    ] = event.currentTarget.value.toUpperCase();
    this.setState(newState);
  };
  myOnSubmit = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateForm(true) === 'success') {
      this.props.onSubmit(this.state);
    }
  };

  render() {
    const result = this.validateForm();
    return (
      <div>
        <form
          className={styles.form}
          onSubmit={this.myOnSubmit}
          autoComplete='off'
        >
          <p>
            Please type in the class code you received in the invitation email
            or letter from your child's or children's teacher.
          </p>
          <FormGroup
            controlId='classCode'
            validationState={this.validateField('classCode')}
          >
            <FormControl
              type='text'
              name='classCode'
              value={this.state.classCode}
              placeholder={`Alphanumeric class code from invitation email or letter`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.classCode && (
              <HelpBlock>{this.helpMessages.classCode}</HelpBlock>
            )}
          </FormGroup>
          <div className={styles.formButton}>
            <PrimaryButton
              orange
              small
              type='submit'
              onClick={this.myOnSubmit}
              disabled={result !== 'success'}
            >
              Use this Code
            </PrimaryButton>
          </div>
        </form>
      </div>
    );
  }
}

export default ConsentCaptureForm;
