import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import './PeekapakButton.css';

const PeekapakButton = (props) => (
  <Button
    className={classnames(
      'peekapakButton',
      props.bsSize && props.bsSize === 'small' && 'peekapakButtonSmall',
      props.className
    )}
    {...props}
  >
    {props.children}
  </Button>
);

export default PeekapakButton;
