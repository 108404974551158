import { Component } from 'react';
import { parseFullName } from 'parse-full-name';
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import styles from './SCSS/ConsentCaptureForm.module.scss';
import myStyles from './SCSS/SignUpCaptureForm.module.scss';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
type FormData = {
  parentsName: string;
  parentsEmail?: string;
  childsName: string;
  childsEmail?: string;
  password: string;
};
type Props = {
  onSubmit: (arg0: FormData) => any;
  savedForm?: FormData;
};
type State = {
  parentsName: string;
  parentsEmail?: string;
  childsName: string;
  childsEmail?: string;
  password: string;
};
const fields = {
  parentsName: {
    type: 'name',
    minLength: 6,
    message: 'Enter your full name',
  },
  parentsEmail: {
    type: 'email',
    minLength: 0,
    message: '',
  },
  childsName: {
    type: 'name',
    minLength: 3,
    message: `Enter your child's name`,
  },
  childsEmail: {
    type: 'email',
    minLength: 0,
    message: '',
  },
  password: {
    type: 'password',
    minLength: 6,
    message: 'Enter a password to secure the account',
  },
};

class SignUpCaptureForm extends Component<Props, State> {
  helpMessages: {
    parentsName?: string;
    parentsEmail?: string;
    childsName?: string;
    childsEmail?: string;
    password?: string;
  } = {};

  constructor(props: Props) {
    super(props);

    if (props.savedForm) {
      this.state = { ...props.savedForm };
    } else {
      this.state = {
        parentsName: '',
        parentsEmail: '',
        childsName: '',
        childsEmail: '',
        password: '',
      };
    }
  }

  validateField = (field: string, isSubmit: boolean = false) => {
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    delete this.helpMessages[`${field}`];
    const l = this.state[`${field}`].trim().length;
    const { minLength, message, type } = fields[`${field}`];

    if (l > 0 && l < minLength) {
      this.helpMessages[
        `${field}`
      ] = `${field} must be at least ${minLength} characters long`;
      return 'error';
    }

    if (l > 0 && type === 'name') {
      const parsedName = parseFullName(this.state[`${field}`].trim());
      const { first, last } = parsedName;

      if (!first || !last) {
        this.helpMessages[`${field}`] = `Please provide first and last name`;
        return 'error';
      }
    }

    if (l > 0 && type === 'email') {
      if (!validateEmail(this.state[`${field}`])) {
        this.helpMessages[
          `${field}`
        ] = `There’s a problem with the format of the email`;
        return 'error';
      }
    }

    if (l < minLength && isSubmit) {
      this.helpMessages[`${field}`] = message;
      return 'error';
    }

    if (l >= minLength) {
      return 'success';
    }

    return null;
  };
  validateForm = (isSubmit: boolean = false) => {
    const keys = Object.keys(fields);
    let i = 0;

    while (i < keys.length) {
      const value = fields[`${keys[i]}`];

      if (this.validateField(keys[i], isSubmit) === 'error') {
        return 'error';
      } else if (
        this.state[`${keys[i]}`].length < fields[`${keys[i]}`].minLength
      ) {
        this.helpMessages[`${keys[i]}`] = `${value.message}`;
        return 'error';
      }

      i += 1;
    }

    return 'success';
  };
  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const newState = {};
    newState[`${event.currentTarget.name}`] = event.currentTarget.value;
    this.setState(newState);
  };
  myOnSubmit = (event: React.SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateForm(true) === 'success') {
      this.props.onSubmit(this.state);
    }
  };

  render() {
    const result = this.validateForm();
    return (
      <div className={myStyles.signUpCaptureForm}>
        <form
          className={styles.form}
          onSubmit={this.myOnSubmit}
          autoComplete='off'
        >
          <FormGroup
            controlId='parentsName'
            validationState={this.validateField('parentsName')}
          >
            <FormControl
              type='text'
              name='parentsName'
              value={this.state.parentsName}
              placeholder={`Enter your full name, e.g. Bobby Baker`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.parentsName && (
              <HelpBlock>{this.helpMessages.parentsName}</HelpBlock>
            )}
          </FormGroup>
          <FormGroup
            controlId='parentsEmail'
            validationState={this.validateField('parentsEmail')}
          >
            <FormControl
              type='text'
              name='parentsEmail'
              value={this.state.parentsEmail}
              placeholder={`Your email address`}
              onChange={this.handleChange}
            />
            <HelpBlock>
              Provide your email address to receive at-home activities and
              updates on your child’s progress
            </HelpBlock>
          </FormGroup>
          <FormGroup
            controlId='childsName'
            validationState={this.validateField('childsName')}
          >
            <FormControl
              type='text'
              name='childsName'
              value={this.state.childsName}
              placeholder={`Child's full name`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.childsName && (
              <HelpBlock>{this.helpMessages.childsName}</HelpBlock>
            )}
          </FormGroup>
          <FormGroup
            controlId='childsEmail'
            validationState={this.validateField('childsEmail')}
          >
            <FormControl
              type='text'
              name='childsEmail'
              value={this.state.childsEmail}
              placeholder={`Child’s email address (if available)`}
              onChange={this.handleChange}
            />
            <HelpBlock>
              A Google Classroom or Clever email address can allow your child to
              log into myPeekaville much more easily
            </HelpBlock>
          </FormGroup>
          <FormGroup
            controlId='password'
            validationState={this.validateField('password')}
          >
            <FormControl
              type='text'
              name='password'
              value={this.state.password}
              placeholder={`Password for child`}
              onChange={this.handleChange}
            />
            <FormControl.Feedback />
            {this.helpMessages.password && (
              <HelpBlock>{this.helpMessages.password}</HelpBlock>
            )}
          </FormGroup>
          <div className={styles.formButton}>
            <PrimaryButton
              orange
              small
              type='submit'
              onClick={this.myOnSubmit}
              disabled={result !== 'success'}
            >
              Create Account
            </PrimaryButton>
          </div>
        </form>
      </div>
    );
  }
}

export default SignUpCaptureForm;
