import {
  captureException,
  captureMessage,
  configureScope,
  init,
  setUser as sentrySetUser,
} from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getConfiguration } from './GlobalFunctions';
import { frontendVersionDescription } from './VersionInformation';

const initializeErrorTrackerFunction = (): void => {
  const configuration = {
    dsn: 'https://c9a4499d53cd41f4bbebea41c102c733@o144502.ingest.sentry.io/1187278',
    environment: getConfiguration('sentry').environment,
    release: frontendVersionDescription,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose', // Facebook borked
      'fb_xd_fragment', // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived', // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i, // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i, // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i, // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i, // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  };
  init(configuration);
};

export const logger = {
  logException: (exceptionObject: Error): void => {
    try {
      captureException(exceptionObject);
    } catch (error) {
      if (error.message !== 'Too Many Requests') {
        throw error;
      }
    }
  },
  logMessage: (message: string): void => {
    try {
      captureMessage(message);
    } catch (error) {
      if (error.message !== 'Too Many Requests') {
        throw error;
      }
    }
  },
};
export const setUser = (userId: string) => {
  sentrySetUser({
    id: userId,
  });
};
export const unsetUser = () => {
  configureScope((scope) => scope.setUser(null));
};
export default initializeErrorTrackerFunction;
