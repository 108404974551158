import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SpinnerOverlay } from './SpinnerOverlay';
import { LoginState, logout as logoutAction } from './UserManagement';
import { logout } from './GlobalFunctions';
import tracker from './tracker-interface';

class Logout extends Component {
  logoutUser = () => {
    logout(this.props.onLogout);
  };

  componentDidMount() {
    this.logoutUser();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginState !== this.props.loginState) {
      this.logoutUser();
    }
  }

  render() {
    if (this.props.loginState !== LoginState.loggedOut) {
      return <SpinnerOverlay isShow={true} />;
    }

    return <Redirect push to='/' />;
  }
}

const mapStateToProps = (state) => ({
  loginState: state.user.loginState,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logoutAction()),
});

export default tracker.withPageView(
  connect(mapStateToProps, mapDispatchToProps)(Logout)
);
