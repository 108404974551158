import { Component } from 'react';
import PeekapakButton from './PeekapakButton';
import NormalLink from './NormalLink';
import tracker from './tracker-interface';
import artwork from './images/disappointed-zoey.png';
import styles from './SCSS/NoParentalConsent.module.scss';
import dialogStyles from './SCSS/Dialog.module.scss';

let history;

const Layout = (props) => {
  history = props.history;

  return (
    <div className={styles.noParentalConsent}>
      <div className={dialogStyles.dialogTitle}>Sorry!</div>
      <div className={styles.noParentalConsentContentLayout}>
        <div className={dialogStyles.dialogText}>
          <p>
            We haven&apos;t received your parent&apos;s permission to let you
            play in myPeekaville.
          </p>
          <p>
            Please ask your parent to check their email to look for the
            Peekaville Consent Form. If they are having trouble with it, they
            can email us at{' '}
            <NormalLink url='mailto:hello@peekapak.com'>
              hello@peekapak.com
            </NormalLink>{' '}
            for help.
          </p>
        </div>
        <img src={artwork} alt='Zoey is disappointed' />
      </div>
    </div>
  );
};

class NoParentalConsent extends Component {
  render() {
    return Layout(this.props);
  }
}

export default tracker.withPageView(NoParentalConsent);
